@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root{
  --primary:#1A1A40;
  --secondary:#270082;
  --medium: #7A0BC0;
  --ternary: #FA58B6;
  --dark: #1B2430;
  --light: #E9D5CA;
} */


*{
  z-index: 0;
}